.footer {
    display: flex;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 100;
    align-items: center;
    justify-content: center;
    background-color: var(--background-color);
    flex-direction: column;


    .tooltip {
        background-color:red;
        color: var(--white);
        padding: 1rem;
        border-radius: 0.5rem;
        position: absolute;
        top: -5rem;
        left: 16.25%;
        transform: translateX(-50%);
        opacity: 1;
        transition: all 0.2s ease-in-out;
        pointer-events: none;
        font-size: 1rem;
        z-index: 4;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        animation: tooltip 2s ease-in-out infinite;

        @media screen and (max-width: 1024px) {
            left: 20%;
        }

        @media screen and (max-width: 768px) {
            left: 30%;
        }

        @media screen and (max-width: 425px) {
            font-size: 0.825rem;
            left: 30%;
        }

        @media screen and (max-width: 375px) {
            left: 32%;
            font-size: 0.825rem;
        }

        .text {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            color: var(--white);
            font-weight: 300;
        }

        .music {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            color: var(--white);
            font-weight: 700;
            font-size: 1rem;
        }


        &::before {
            content: '';
            position: absolute;
            top: 100%;
            left: 50%;
            transform: translateX(-50%);
            border: 0.5rem solid transparent;
            border-top-color: var(--white);
        }

        &::after {
            content: '';
            position: absolute;
            top: 100%;
            left: 50%;
            transform: translateX(-50%);
            border: 0.5rem solid transparent;
            border-top-color: red;
        }

        @keyframes tooltip {
            0% {

                transform: translateX(-50%) translateY(-1rem);
            }
            50% {

                transform: translateX(-50%) translateY(0);
            }
            100% {

                transform: translateX(-50%) translateY(-1rem);
            }
        }

    }
    .content {
        display: flex;
        width: 100%;
        max-width: var(--max-width);
        align-items: center;
        justify-content: center;
        padding: 0 1rem;
        height: 60px;
        width: 100%;
        font-family: monospace;
        .text {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            color: var(--white);
        }


    }
}
