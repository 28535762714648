:root {
    --max-width: 1200px;
    --border-radius: 8px;
    --height-header: 60px;
    --background-color: #1a171c;
    --error-color: #dc3545;
    --confirm-color: #28a745;
    --disabled-color: #eaedf7;
    --neutral: #343a40;
    --white: #ffffff;
    --black: #000000;
    --facebook-color: #3b5998;
    --shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
}

*,
*::before,
*::after {
    box-sizing: border-box;
    margin: 0;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    padding: 0;
    scrollbar-width: none; /* Firefox */
}

body {
    background-color: var(--background-color);
    height: 100vh;
    margin: 0;
    padding: 0;
    width: 100%;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0px;
    padding: 0px;
}

main {
    // height: calc(100vh - var(--height-header) - var(--height-menu));
    // min-height: calc(100% - var(--height-header) - var(--height-menu));
    // padding-bottom: calc(var(--height-menu) + var(--height-header));
    // padding-top: var(--height-header);
    position: relative;
}

a {
    color: var(--text-color-white);
    text-decoration: none;
}

ul,
ol,
li {
    list-style: "";
}

@media (max-width: 1980px) {
    html {
        font-size: 100%; // 16px
    }
}
@media (max-width: 1080px) {
    html {
        font-size: 93.75%; // 15px
    }
}
@media (max-width: 720px) {
    html {
        font-size: 87.5%; // 14px
    }
}
@media (max-width: 600px) {
    html {
        font-size: 75%; // 12px
    }

    ::-webkit-scrollbar {
        width: 0px;
    }
}

@media (max-width: 280px) {
    html {
        font-size: 62.5%; // 10px
    }

    ::-webkit-scrollbar {
        width: 0px;
    }
}
