.header {
    display: flex;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    align-items: center;
    justify-content: center;
    background-color: var(--background-color);
    flex-direction: column;

    .content {
        display: flex;
        width: 100%;
        max-width: var(--max-width);
        align-items: center;
        justify-content: space-between;
        padding: 0 1rem;
        height: var(--height-header);
        .logo {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            width: 100%;

            a {
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
            }
        }

        .links {
            color: var(--white);
        }
    }
}
